<template>

  <vehicule-form
    :title="title"
    @submitVehiculeForm="onSubmit"
    @submitVehiculeFormAndPrintEtiquette="onSubmitAndPrint"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FileManager from '@/utils/FileManager'
import VehiculeForm from './VehiculeForm.vue'

export default {
  components: {
    VehiculeForm,
  },
  data() {
    return {
      title: "Ajout d'un véhicule",
    }
  },
  setup() {
    const toast = useToast()

    const addVehicule = formValue => store.dispatch('vehicule/addVehicule', formValue)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de l'ajout du véhicule",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const goToNextPage = addNewVehicleAfterSave => {
      if (addNewVehicleAfterSave) {
        router.go()
      } else {
        router.back()
      }
    }

    const onSubmit = (formValue, addNewVehicleAfterSave) => addVehicule(formValue)
      .then(goToNextPage(addNewVehicleAfterSave))

    const onSubmitAndPrint = (actionName, formValue, addNewVehicleAfterSave) => addVehicule(formValue)
      .then(response => store.dispatch('vehicule/fetchEtiquetteByVehicule', response.data.id))
      .then(response => {
        if (response && response.data) { FileManager.forceFileDownload(response.data.filename, response.data.contentType, response.data.body) }
        goToNextPage(addNewVehicleAfterSave)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Impossible d\'imprimer l\'étiquette',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      onSubmit,
      onSubmitAndPrint,
    }
  },
}
</script>
